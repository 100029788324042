.admin-recommendation-dashboard .attorney-recommendation {
  border: 1px solid #EEE;
  padding: 1rem;
  margin-bottom: 1rem; }

.admin-recommendation-dashboard .location {
  color: #777; }

.admin-recommendation-dashboard .attorney-profile-picture-wrapper, .admin-recommendation-dashboard .attorney-no-profile-pic {
  margin-right: 1rem; }

.admin-recommendation-dashboard ul.attorney-practice-areas {
  margin: 0; }

.admin-recommendation-dashboard .recommendation-display_score .rating-container {
  text-align: left;
  color: #47a9cf; }
  .admin-recommendation-dashboard .recommendation-display_score .rating-container .rating {
    font-size: 1.7rem;
    margin: 0 0.17rem 0 0.17rem; }
