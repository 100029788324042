span.radio {
  display: inline-block;
  width: 5rem;
  margin-right: 2rem;
  position: relative; }
  span.radio input[type="radio"].radio-bubble {
    position: absolute;
    visibility: hidden; }
    span.radio input[type="radio"].radio-bubble ~ .check {
      position: absolute;
      top: 0.5rem;
      left: 0;
      display: inline-block;
      border-radius: 100%;
      height: 2.5rem;
      width: 2.5rem;
      border: 0.5rem solid #0898c4;
      cursor: pointer; }
    span.radio input[type="radio"].radio-bubble:checked ~ .check {
      background: #0898c4;
      transition: background 0.25s ease-in-out;
      -webkit-transition: background 0.25s ease-in-out; }
  span.radio label {
    padding-left: 3rem; }
