div.settings-main-container table.agents-settings-table tr.agent-deactivated {
  opacity: 0.5; }

div.settings-main-container table.agents-settings-table td {
  vertical-align: middle; }

div.settings-main-container table.agents-settings-table .agent-table-buttons button, div.settings-main-container table.agents-settings-table a {
  margin: 0 .1rem; }

div.settings-main-container div.settings-buttons-container {
  padding-bottom: 2rem; }

div.settings-main-container #settings-tabs div.tab-content {
  margin-top: 5rem; }

table.payment-sources-table button.bank-verify-button {
  margin-left: .5rem;
  padding: 0;
  vertical-align: inherit;
  border: 0; }

div.source-type-label {
  text-align: center;
  font-size: 2.2rem;
  padding-top: .5rem;
  font-weight: 300; }

.create-bank-form .bank-form-divider {
  border-bottom: 1px solid #DDD;
  margin-bottom: 2rem; }

.create-bank-form .ach-tos {
  overflow-y: scroll;
  height: 16rem; }

.credit-card-container {
  margin-top: .5rem;
  margin-bottom: 2rem; }

button.active-source-button {
  cursor: default; }
  button.active-source-button:hover {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da; }

button.inactive-source-button:focus {
  background: white;
  border-color: #ccc;
  outline: initial; }
