.list-filters h3, .list-filters h4, .list-filters label.title, .list-filters button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.55rem; }

.list-filters .list-filters-heading {
  padding-bottom: 15px; }

.list-filters label.title {
  color: #0898c4; }

.main-info-container {
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #CCC; }
  @media only screen and (max-width: 360px) {
    .main-info-container {
      padding: 1rem; }
      .main-info-container .panel-body {
        padding: 0px; } }

.main-info-title {
  color: #47a9cf;
  margin: 0 0 25px 0; }

.main-info-section {
  margin-top: 15px; }

.header-action-dropdown {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #47a9cf;
  background-color: white;
  border: 1px solid #47a9cf;
  font-weight: 500;
  font-size: 1.3rem; }
  @media screen and (min-width: 768px) {
    .header-action-dropdown {
      font-size: 1.2rem; } }
  .header-action-dropdown:hover, .header-action-dropdown:focus {
    background-color: #f3f3f3;
    color: #246f8c;
    border-color: #246f8c; }

.dropdown-button-link, div.actions-dropdown button {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }

span.label.via-label, span.label.available-label {
  font-size: 13.5px;
  margin-left: .5rem;
  background-color: #F4C603; }

span.label.available-label {
  background-color: #5cb85c; }

.toggle-offers {
  display: inline-block;
  font-size: 1.75rem; }

.status-filters-container {
  display: inline-block;
  margin-bottom: 2.7rem; }
  @media screen and (min-width: 992px) {
    .status-filters-container {
      margin-bottom: 3rem; } }
  .status-filters-container h4 {
    display: inline-block;
    margin: 0; }
  .status-filters-container button {
    padding: 0;
    border: none; }

.filters ul {
  padding: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 6px; }
  @media screen and (min-width: 480px) {
    .filters ul {
      border: none;
      background-color: white;
      border-bottom: 1px solid #e3e3e3;
      border-radius: 0;
      padding-bottom: 0; } }
  .filters ul li {
    display: inline-block;
    margin-right: 1.3rem;
    margin-bottom: 0.5rem; }
  .filters ul li.filter {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 1.3rem;
    cursor: pointer;
    color: #B3B3B3; }
    @media screen and (min-width: 480px) {
      .filters ul li.filter {
        margin-bottom: 0;
        padding-bottom: .5rem; } }
    .filters ul li.filter.selected {
      color: #333;
      font-weight: 600;
      border-bottom: 3px solid #0898c4; }
  .filters ul li.heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.55rem; }
    .filters ul li.heading.filter-title {
      font-size: 1.8rem;
      font-weight: 400; }
  .filters ul li.subheading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.55rem;
    margin: 2rem 0 1rem 0;
    color: #0898c4; }
  .filters ul li.heading {
    margin: 1rem 0 2rem 0; }
  .filters ul li.separator {
    border-bottom: 1px solid #CCC;
    margin: 1.5rem 0; }

.application {
  position: relative; }
  .application .panel-group {
    margin-bottom: 1rem; }
  .application .section-title {
    margin: 0 0 2rem 0;
    font-size: 16px;
    color: #429CBF;
    font-weight: bold; }
  .application .actions {
    margin-top: 2rem;
    margin-bottom: 0; }
    .application .actions button {
      text-transform: uppercase;
      border-radius: 20px; }
      .application .actions button.btn-default {
        color: #000;
        border-color: transparent;
        box-shadow: 0 0 0 1px #000; }
      @media screen and (min-width: 768px) {
        .application .actions button {
          display: inline-block;
          width: auto; }
          .application .actions button + button {
            margin-top: 0; } }
      .application .actions button.application-accept {
        float: right; }
      .application .actions button.application-hide, .application .actions button.application-tooltip {
        display: none; }
        @media screen and (min-width: 768px) {
          .application .actions button.application-hide, .application .actions button.application-tooltip {
            display: inline-block; } }
      .application .actions button:not(:last-child) {
        margin-right: 1rem; }
      .application .actions button.application-reject:hover {
        background-color: #a02622;
        box-shadow: 0 0 0 1px #761c19;
        color: #FFF; }
    .application .actions .action-tooltip-icon {
      color: #429CBF;
      font-size: 1.8rem; }
  .application .application-tabs-container [role=tablist] {
    margin: 0;
    border-bottom: none; }
    .application .application-tabs-container [role=tablist] li.active a[role=tab]:hover, .application .application-tabs-container [role=tablist] li.active a[role=tab]:focus {
      border: none;
      color: #FFF;
      background-color: #37322C; }
  .application .application-tabs-container [role=tab] {
    border: none;
    border-bottom: 1px solid #CCC;
    border-radius: 5px 5px 0 0;
    line-height: 1;
    margin: 0 1rem;
    padding: 1rem 1.5rem;
    text-align: center;
    color: #666;
    background-color: #EEE;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    text-transform: uppercase; }
    .application .application-tabs-container [role=tab]:hover:not([aria-selected=true]) {
      color: #333333;
      background-color: #bbbbbb; }
    .application .application-tabs-container [role=tab]:focus {
      box-shadow: none;
      outline: none; }
    .application .application-tabs-container [role=tab]:focus:after {
      display: none; }
  .application .application-tabs-container [aria-selected=true] {
    color: #FFF;
    background-color: #37322C; }
  .application .application-tabs-container [role=tabpanel] {
    transition: all 0.7s;
    padding: 3rem;
    border: 1px solid #CCC;
    border-radius: 5px; }

@keyframes scale {
  0% {
    transform: scale(0.9);
    opacity: 0.01; }
  50% {
    transform: scale(1.01);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 1; } }

span.application-available-label {
  margin-left: 5px; }
  span.application-available-label .available-label {
    vertical-align: -webkit-baseline-middle; }
    @media screen and (min-width: 768px) {
      span.application-available-label .available-label {
        vertical-align: initial; } }

h2.applicant-name {
  margin: 0 0 2rem 0;
  display: inline-block; }
  @media screen and (min-width: 768px) {
    h2.applicant-name {
      margin-bottom: 4rem; } }

.application-content {
  padding: 1rem; }

.tab-bar {
  border-bottom: 1px solid #EEE;
  list-style: none;
  padding-left: 0;
  display: block;
  line-height: 1.5; }
  .tab-bar .application-tab {
    float: left;
    display: block;
    margin-bottom: -1px;
    position: relative; }
    .tab-bar .application-tab a {
      padding: 1rem;
      display: block;
      border-right: 1px solid #EEE;
      line-height: 1.5;
      position: relative;
      color: #000;
      text-transform: uppercase; }
    .tab-bar .application-tab.active {
      font-weight: 600;
      border-bottom-color: transparent;
      background-color: #FFF;
      cursor: default; }

.application-status-container {
  position: relative;
  display: inline-block; }
  @media screen and (min-width: 990px) {
    .application-status-container {
      text-align: right;
      position: absolute;
      top: 0.5rem;
      right: 0; } }
  @media screen and (min-width: 300px) {
    .application-status-container {
      float: right; } }

.application-status {
  white-space: nowrap; }

.badge.application-status {
  line-height: 2.5rem;
  border-radius: 1.2rem;
  padding: 0 1rem;
  vertical-align: top; }

.badge.application-accepted {
  background-color: #5cb85c; }

.badge.application-rejected {
  background-color: #922729; }

.badge.application-pending {
  background-color: #efca67; }

.tooltip-content h5 {
  font-weight: 600;
  margin-bottom: 0.25rem; }

.tooltip-content p, .tooltip-content ul {
  font-size: 90%;
  margin: 0 0 1rem 0;
  display: block; }

.conflicts, .attorney-profile {
  color: #666666; }
  .conflicts div:not(:last-child) > dl, .conflicts div:not(:last-child).work-experience, .attorney-profile div:not(:last-child) > dl, .attorney-profile div:not(:last-child).work-experience {
    border-bottom: 1px solid #EEE;
    padding-bottom: 2rem;
    margin-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .conflicts div.col-md-6:nth-last-child(2) dl, .conflicts div.col-md-6:nth-last-child(2) > div.work-experience, .attorney-profile div.col-md-6:nth-last-child(2) dl, .attorney-profile div.col-md-6:nth-last-child(2) > div.work-experience {
      border-bottom: none !important; } }

.applications-transition-appear, .applications-transition-enter {
  opacity: 0.01; }
  .applications-transition-appear.applications-transition-appear-active, .applications-transition-appear.applications-transition-enter-active, .applications-transition-enter.applications-transition-appear-active, .applications-transition-enter.applications-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
    -webkit-transition: opacity 500ms ease-in; }

.applications-transition-leave {
  opacity: 1; }
  .applications-transition-leave.applications-transition-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }

.expander {
  margin-top: 1rem; }

.offcanvas {
  display: block;
  position: fixed;
  right: -200px;
  top: 0;
  background-color: #EEE;
  z-index: 999;
  height: 100%;
  padding: 1rem 1rem 1rem 1rem;
  width: 200px;
  transition: right 200ms ease-in-out;
  -webkit-transition: right 200ms ease-in-out; }
  .offcanvas.offcanvas-open {
    right: 0; }
  .offcanvas.offcanvas-closed {
    right: -200px;
    transition: right 200ms ease-in-out;
    -webkit-transition: right 200ms ease-in-out; }

.offcanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.5); }

.mode-toggle {
  font-size: 2rem;
  padding: 0;
  color: inherit; }

.applied-for {
  margin-bottom: 1rem;
  color: #AAA; }
  @media screen and (min-width: 768px) {
    .applied-for {
      float: right; } }

.sort-by-dropdown {
  margin-top: 10px; }

.hide-bulk-action {
  visibility: hidden; }

tbody.application-panels .selected-app-row td {
  background-color: #F5F5F5; }

tbody.application-panels tr:hover:not(.full-application-row) {
  cursor: pointer;
  background-color: #F9F9F9; }

tbody.application-panels tr.full-application-row {
  background-color: #FDFDFD;
  -moz-box-shadow: 2px 2px 30px #F0F0F0, -2px 2px 30px #F0F0F0;
  -webkit-box-shadow: 2px 2px 30px #F0F0F0, -2px 2px 30px #F0F0F0;
  box-shadow: 2px 2px 30px #F0F0F0, -2px 2px 30px #F0F0F0; }
  tbody.application-panels tr.full-application-row td {
    max-width: 200px; }
    tbody.application-panels tr.full-application-row td .application-container {
      padding: 0; }
      @media screen and (min-width: 992px) {
        tbody.application-panels tr.full-application-row td .application-container {
          padding: 20px 15px; } }

tbody.application-panels tr td {
  vertical-align: middle !important; }

tbody.application-panels td.table-row-checkbox, tbody.application-panels td.table-row-checkbox label, tbody.application-panels td.table-row-actions {
  cursor: default; }

div.actions-dropdown button.no-applications-button {
  cursor: not-allowed;
  color: grey;
  text-decoration: none; }

div.job-select-header {
  margin-bottom: 2rem; }
  @media screen and (min-width: 992px) {
    div.job-select-header {
      margin-bottom: 2.3rem; } }

.num-displayed {
  margin-top: 1rem;
  margin-bottom: .5rem; }

div.actions-dropdown li :hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5; }

div.actions-dropdown li button {
  border: none; }

div.actions-dropdown li a {
  font-size: 14px;
  padding: 3px 20px; }
