.dropzone-container {
  margin: 2.3rem 0; }
  .dropzone-container .dropzone {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    height: 150px;
    border: 2px dashed #BBB;
    border-radius: 5px; }
    .dropzone-container .dropzone i {
      display: block;
      color: gold;
      font-size: 60px; }
    .dropzone-container .dropzone .dropzone-text {
      display: block;
      margin-top: 1.6rem;
      color: black; }
