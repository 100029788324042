.no-content-message {
  margin: 4rem auto;
  text-align: center;
  font-size: 1.6rem; }

@media (max-width: 767px) {
  .table-responsive tr:nth-last-child(-n+2) .dropdown-menu,
  .table-responsive tr:nth-last-child(-n+2) .dropdown-toggle {
    position: static !important; } }

@media screen and (min-width: 768px) {
  .table-responsive {
    overflow: visible; } }

@media screen and (max-width: 767px) {
  #mobile-width {
    white-space: pre-wrap;
    display: table; } }

.react-base-public {
  min-height: 100vh;
  height: 100%; }

p.header-copy {
  font-weight: 300;
  font-size: 1.75rem; }

.panel-heading a {
  cursor: pointer;
  display: block; }

.panel-heading .panel-title a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f146";
  float: right; }

.panel-heading .panel-title a.collapsed:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0fe"; }

.highlight {
  background-color: yellow; }
