.list-filters h3, .list-filters h4, .list-filters label.title, .list-filters button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.55rem; }

.list-filters .list-filters-heading {
  padding-bottom: 15px; }

.list-filters label.title {
  color: #0898c4; }

.main-info-container {
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #CCC; }
  @media only screen and (max-width: 360px) {
    .main-info-container {
      padding: 1rem; }
      .main-info-container .panel-body {
        padding: 0px; } }

.main-info-title {
  color: #47a9cf;
  margin: 0 0 25px 0; }

.main-info-section {
  margin-top: 15px; }

.header-action-dropdown, .header-action-buttontoolbar button {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #47a9cf;
  background-color: white;
  border: 1px solid #47a9cf;
  font-weight: 500;
  font-size: 1.3rem; }
  @media screen and (min-width: 768px) {
    .header-action-dropdown, .header-action-buttontoolbar button {
      font-size: 1.2rem; } }
  .header-action-dropdown:hover, .header-action-buttontoolbar button:hover, .header-action-dropdown:focus, .header-action-buttontoolbar button:focus {
    background-color: #f3f3f3;
    color: #246f8c;
    border-color: #246f8c; }

.dropdown-button-link {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }

span.label.via-label, span.label.available-label {
  font-size: 13.5px;
  margin-left: .5rem;
  background-color: #F4C603; }

span.label.available-label {
  background-color: #5cb85c; }

.attorney-availability-container hr {
  margin: 13px 0; }

.attorney-availability-container dl {
  margin: 0; }

.attorney-main-info hr {
  margin: 20px 0; }

.attorney-experience-section:last-child hr {
  display: none; }

@media screen and (min-width: 768px) {
  .experience-details {
    display: inline-block; } }

@media screen and (min-width: 768px) {
  .experience-date-container {
    display: inline-block;
    float: right; } }

@media screen and (min-width: 768px) {
  .experience-info {
    clear: both; } }

.experience-description {
  margin-top: 20px; }

.enclosing-container {
  margin: 20px 5px 0 5px; }

.attorney-header-container {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .attorney-header-container {
      text-align: left; } }

.attorney-headers {
  margin-top: 5px;
  margin-bottom: 15px; }
  @media screen and (min-width: 768px) {
    .attorney-headers {
      margin-top: 0px; } }

.attorney-headers > * {
  margin: 0 0 4px 0; }

.attorney-name.attorney-title {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0; }

.attorney-title-large {
  font-size: 2.2rem;
  margin-bottom: 7px;
  display: inline; }

.attorney-title-small {
  margin-bottom: 7px;
  display: inline; }

.attorney-subheaders > * {
  margin: 0 0 6px 0;
  font-size: 1.8rem; }
  @media screen and (min-width: 768px) {
    .attorney-subheaders > * {
      text-align: right; } }

.attorney-rate {
  color: #808080; }

.attorney-display_score .rating-container {
  text-align: inherit;
  color: #47a9cf; }
  .attorney-display_score .rating-container .rating {
    font-size: 2.1rem;
    margin: 0 0.25rem 0 0.25rem;
    pointer-events: none; }

.primary-header {
  font-weight: 700; }

.header-tags-row {
  margin-top: 1rem; }

.header-action-buttontoolbar {
  display: flex;
  align-items: center;
  justify-content: center; }
  .header-action-buttontoolbar .dropdown.btn-group {
    width: 100%; }

.contractor-actions .jobs-loading {
  cursor: wait; }

.contractor-actions .invite-to-job-menu-header {
  cursor: default; }

.experience-headers {
  font-size: 2rem; }

.practice-areas ul.attorney-practice-areas {
  margin: 0px; }
  .practice-areas ul.attorney-practice-areas li {
    background-color: #47a9cf;
    border-radius: 30px;
    cursor: pointer; }
    .practice-areas ul.attorney-practice-areas li p {
      text-transform: uppercase;
      color: white;
      font-size: 1.1rem;
      text-decoration: none;
      margin: 0px; }

.profile-picture-component-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.secondary-header {
  font-size: 1.6rem;
  font-weight: 700; }

.tertiary-header, .accent-title {
  font-size: 1.6rem; }

.accent-title {
  color: #808080; }

.lang-tooltip {
  position: relative;
  font-size: 1.5rem;
  color: #808080;
  top: -6px; }
  @media screen and (min-width: 768px) {
    .lang-tooltip {
      font-size: 1.6rem;
      right: -2px; } }

.attorney-availability-container span {
  text-align: right; }

div.fav-btn-desktop a.talentpools-button {
  margin-top: 10px;
  float: right; }

div.fav-btn-mobile {
  margin-bottom: 15px; }

.attorney-icon-base {
  display: inline-block; }
  .attorney-icon-base .fa-stack-2x {
    color: #0898c4; }
  .attorney-icon-base .fa-stack-1x {
    color: white;
    left: 1.9%;
    top: -2.0%; }
