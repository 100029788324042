.review-form-public {
  margin: 1rem 0 3rem 0; }
  .review-form-public h1 {
    margin: 2rem 0 1rem 0;
    font-size: 2.8rem; }
    @media screen and (min-width: 768px) {
      .review-form-public h1 {
        font-size: 3rem; } }
  .review-form-public .review-description {
    margin: 2rem 0 4rem 0; }
    @media screen and (min-width: 768px) {
      .review-form-public .review-description {
        margin: 4rem 0; } }
  .review-form-public .form-group {
    margin-bottom: 2rem; }
