/* This has been customized to look like a react-bootstrap field component. */

.react-multi-email {
    display: block;
    width: 100%;
    min-height: 34px;
    margin: 0 0 8px 0;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    position: relative;
}
  .react-multi-email > span[data-placeholder] {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 12px;
    line-height: 34px;
    color:  #999 !important;
  }
  .react-multi-email.focused {
    border-color: #85b7d9;
    background: #fff;
  }

  .react-multi-email.empty > span[data-placeholder] {
    display: inline;
    color: #ccc;
  }
  .react-multi-email.focused > span[data-placeholder] {
    display: none;
  }

  .react-multi-email > input {
    width: auto !important;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    vertical-align: baseline !important;
  }

  .react-multi-email [data-tag] {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 1px;
    background-color:#ebf5ff;
    background-image: none;
    padding: 0.5em 0.8em;
    color:#007eff;
    text-transform: none;
    border: 1px solid rgba(0, 126, 255, 0.24);
    border-radius: 34px;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
    font-size: 12px;
  }
  .react-multi-email [data-tag] [data-tag-handle] {
    margin-left: 0.833em;
    cursor: pointer;
  }
