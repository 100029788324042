.list-filters h3, .list-filters h4, .list-filters label.title, .list-filters button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.55rem; }

.list-filters .list-filters-heading {
  padding-bottom: 15px; }

.list-filters label.title {
  color: #0898c4; }

.main-info-container {
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #CCC; }
  @media only screen and (max-width: 360px) {
    .main-info-container {
      padding: 1rem; }
      .main-info-container .panel-body {
        padding: 0px; } }

.main-info-title {
  color: #47a9cf;
  margin: 0 0 25px 0; }

.main-info-section {
  margin-top: 15px; }

.header-action-dropdown, .job-header div.job-header-title-actions button.job-actions-dropdown {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #47a9cf;
  background-color: white;
  border: 1px solid #47a9cf;
  font-weight: 500;
  font-size: 1.3rem; }
  @media screen and (min-width: 768px) {
    .header-action-dropdown, .job-header div.job-header-title-actions button.job-actions-dropdown {
      font-size: 1.2rem; } }
  .header-action-dropdown:hover, .job-header div.job-header-title-actions button.job-actions-dropdown:hover, .header-action-dropdown:focus, .job-header div.job-header-title-actions button.job-actions-dropdown:focus {
    background-color: #f3f3f3;
    color: #246f8c;
    border-color: #246f8c; }

.dropdown-button-link {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }

span.label.via-label, span.label.available-label {
  font-size: 13.5px;
  margin-left: .5rem;
  background-color: #F4C603; }

span.label.available-label {
  background-color: #5cb85c; }

.jobs-list-enter {
  opacity: 0.01; }
  .jobs-list-enter.jobs-list-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out; }

.jobs-list-leave {
  opacity: 1; }
  .jobs-list-leave.jobs-list-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in-out; }

.jobs-title {
  display: inline-block; }

.manage-workers-btn {
  margin: 3px 0 20px 0; }
  @media screen and (min-width: 338px) {
    .manage-workers-btn {
      margin: 0 4px 0; } }

.jobs-showing-info > p {
  margin: 0; }
  @media screen and (min-width: 338px) {
    .jobs-showing-info > p {
      margin-top: 25px; } }

.closed-jobs-tooltip {
  vertical-align: super; }

/* Job Detail */
.job-header {
  margin-bottom: 1.3rem; }
  .job-header .back-button {
    margin-bottom: 1rem; }
  .job-header div.job-header-title-actions {
    margin: 1.3rem 0; }
    @media screen and (min-width: 768px) {
      .job-header div.job-header-title-actions {
        margin: 1.5rem 0; } }
    .job-header div.job-header-title-actions .job-title-header {
      padding-bottom: 1rem; }
      .job-header div.job-header-title-actions .job-title-header h1 {
        font-size: 2.9rem;
        margin: 0; }
      .job-header div.job-header-title-actions .job-title-header span {
        margin-left: 1rem;
        font-weight: bold; }
    .job-header div.job-header-title-actions button.job-actions-dropdown {
      text-align: center;
      font-size: 1.2rem;
      width: 100%; }
      @media screen and (min-width: 992px) {
        .job-header div.job-header-title-actions button.job-actions-dropdown {
          margin: 0; } }

.job-detail-profile-pic {
  text-align: center;
  margin: 1.8rem; }
  @media screen and (min-width: 768px) {
    .job-detail-profile-pic {
      margin: 0;
      margin-top: 2rem; } }

.job-detail-invoice-row td span {
  display: block; }

.job-detail-invoice-row td * {
  margin-bottom: 5px; }

div.job-applications-header {
  overflow: hidden; }

div.job-manage-applications {
  margin-bottom: 1em; }
  @media screen and (min-width: 480px) {
    div.job-manage-applications {
      float: right; } }

.job-detail-apps-container li > a {
  padding: 10px; }

.job-application-profile-pic div.row {
  padding: 1rem 0; }

.job-application-profile-pic > h4 {
  text-align: center;
  margin-top: 1.5em; }

.file-upload-instructions {
  margin-top: -1em;
  margin-bottom: 1.5em; }

.file-upload-dropzone {
  border: 1px dashed #666666;
  border-radius: 30px;
  margin: 0 auto 3em auto;
  padding: 1.5em;
  color: #47a9cf;
  cursor: pointer; }
  @media screen and (min-width: 992px) {
    .file-upload-dropzone {
      width: 65%;
      padding: 2em; } }
  .file-upload-dropzone:active {
    border-width: 2px; }
  .file-upload-dropzone i {
    color: #FECB00; }

.active-file-upload-dropzone {
  border-radius: 50px;
  color: #47a9cf;
  border-width: 2px; }
