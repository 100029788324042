.job-offer-notification div.jumbotron .title {
  font-size: 2.5rem; }

.job-offer-notification div.jumbotron .action-buttons {
  text-align: center; }
  .job-offer-notification div.jumbotron .action-buttons .btn-toolbar {
    display: inline-block; }

.job-offer-notification div.jumbotron li p {
  margin-bottom: 0; }

.job-offer-notification div.jumbotron p {
  font-size: 2rem; }

.job-offer-notification div.jumbotron .offer-details dd {
  margin-bottom: .5rem; }
