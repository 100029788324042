.dashboard-panel .panel-content {
  margin: 15px 0;
  border-radius: 5px;
  background-color: #F2F2F2;
  min-height: 32rem;
  padding: 0; }

.dashboard-panel h2 {
  padding: 2rem;
  text-transform: capitalize; }

.dashboard-panel .action {
  background-color: #FECB00;
  color: #FFF;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  display: block;
  text-align: center;
  font-size: 1.75rem;
  margin: 0 2rem 2rem 2rem; }
  .dashboard-panel .action:hover, .dashboard-panel .action:focus {
    text-decoration: none;
    background-color: #cba200; }

.dashboard-panel .recommended-attorney-snippet button, .dashboard-panel button.action {
  text-transform: uppercase;
  border-radius: 30px;
  padding: 0.1rem 1rem; }

.dashboard-panel .line-item {
  display: table;
  width: 100%;
  min-height: 6rem; }
  .dashboard-panel .line-item > div {
    vertical-align: middle;
    display: table-cell;
    padding: 1rem 0; }
  .dashboard-panel .line-item:nth-child(odd) {
    background-color: #f7f7f7; }
  .dashboard-panel .line-item:nth-child(even) {
    background-color: #e5e5e5; }
  .dashboard-panel .line-item .subline, .dashboard-panel .line-item .delete-favorite {
    color: #999; }
  .dashboard-panel .line-item + .line-item + .line-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .dashboard-panel .line-item {
    color: inherit; }
    .dashboard-panel .line-item:hover {
      text-decoration: none; }

.dashboard-panel .line-item-title {
  font-weight: 600;
  font-size: 120%; }

.dashboard-panel .line-item-alert {
  font-size: 120%; }

.dashboard-panel form {
  margin-top: 1rem; }

.messages-preview tbody > tr > td {
  border-top: none;
  vertical-align: middle;
  height: 6rem; }
  .messages-preview tbody > tr > td:first-child {
    padding-left: 1.5rem; }
  .messages-preview tbody > tr > td:last-child {
    padding-right: 1.5rem; }
  .messages-preview tbody > tr > td.name {
    white-space: nowrap; }
  .messages-preview tbody > tr > td.subject {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 40rem; }

.messages-preview tbody tr:nth-child(odd) {
  background-color: #f7f7f7; }

.messages-preview tbody tr:nth-child(even) {
  background-color: #e5e5e5; }

.messages-preview tbody tr:last-child > td {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.messages-preview .unread {
  font-weight: 600; }

.error {
  color: #a02622; }

.alert-color {
  color: #DC2D25; }

.success {
  color: #5cb85c; }

.review-modal-content-container .modal-body p.form-description {
  margin-bottom: 2rem; }

.review-modal-content-container .media-body {
  vertical-align: middle; }

i.fa-inline {
  margin-right: 0.5rem; }

.recommended-attorney-snippet a {
  color: inherit; }
  .recommended-attorney-snippet a:hover {
    text-decoration: none; }

.recommended-attorney-snippet .recommendation-toolbar > .btn {
  float: none; }

.recommended-attorney-snippet .name {
  float: none; }
  @media screen and (min-width: 768px) {
    .recommended-attorney-snippet .name {
      float: left; } }

.recommended-attorney-snippet .availability-container {
  margin: 0.5rem 0;
  float: right; }
  @media screen and (min-width: 768px) {
    .recommended-attorney-snippet .availability-container {
      margin: 0;
      float: right; } }

.recommended-attorney-snippet .is-available {
  vertical-align: baseline;
  padding: 0.22rem 0.5rem; }

.post-job-cta {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.8rem;
  z-index: 1015;
  position: relative; }
  .post-job-cta a {
    width: 100%; }
  .post-job-cta .active {
    background-color: #5cb85c;
    border-color: #4cae4c;
    box-shadow: none; }
  @media screen and (min-width: 768px) {
    .post-job-cta.dashboard-btn {
      display: none; } }

.alerts-pagination-container {
  margin-top: 1rem;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .alerts-pagination-container {
      text-align: right; } }
