.time-entries-tracker .time-entries table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: none;
  vertical-align: inherit; }

.time-entries-tracker .time-entries .entries-week-title {
  font-size: 1.6rem; }

.fixed-rate-tracker .fixed-rate-entries table > tbody > tr > td {
  padding: 2rem 8px;
  vertical-align: middle; }
  .fixed-rate-tracker .fixed-rate-entries table > tbody > tr > td div.entry-number {
    font-size: 1.7rem;
    font-weight: 200;
    white-space: nowrap;
    display: inline-block;
    padding-right: 4rem; }
  .fixed-rate-tracker .fixed-rate-entries table > tbody > tr > td .label.label-info {
    font-size: 1.7rem; }

.fixed-rate-tracker .fixed-rate-entries .event-attr-titles div {
  font-weight: 700;
  text-align: right; }

.fixed-rate-tracker .fixed-rate-entries .event-attr-titles div {
  white-space: nowrap; }

.expense-tracker .expenses-form .react-datepicker-popper {
  z-index: 3; }

.expense-tracker .expenses-list table > tbody > tr > td {
  padding-top: 1.7rem;
  padding-bottom: 1.7rem; }
