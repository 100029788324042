.profile-section {
  margin: 2rem 0;
  word-break: break-all; }

.profile-picture-container {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .profile-picture-container {
      text-align: left;
      float: left; }
      .profile-picture-container img {
        margin: 0 2rem 2rem 0; } }

.profile-picture {
  max-height: 175px;
  max-width: 175px; }

.summary-title {
  font-size: 1.5rem;
  color: #000; }
  @media screen and (min-width: 768px) {
    .summary-title {
      margin: 0; } }

.section-heading {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1.8rem; }

.in-network {
  color: #5cb85c; }

.media-heading {
  margin-bottom: 1rem; }
  .media-heading h4 {
    font-weight: 600; }

.profile-picture-expand-enter {
  max-width: 0;
  overflow-x: hidden; }
  .profile-picture-expand-enter.profile-picture-expand-enter-active {
    max-width: 175px;
    transition: max-width 300ms ease-in-out; }

.profile-picture-expand-leave {
  max-width: 175px; }
  .profile-picture-expand-leave.profile-picture-expand-leave-active {
    max-width: 0;
    overflow-x: hidden;
    transition: max-width 300ms ease-in-out; }

div.attorney-profile-collapsible {
  border: none;
  box-shadow: none;
  margin: 0; }
  div.attorney-profile-collapsible .panel-body {
    padding: 0; }

.work-experience .company-name {
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .work-experience .company-name {
      float: left; } }

.work-experience .dates {
  margin-bottom: 1rem;
  color: #FECB00; }
  @media screen and (min-width: 768px) {
    .work-experience .dates {
      text-align: right;
      float: right; } }

.attorney-profile table.table > thead > tr th {
  border-bottom: 1px solid #000; }
  @media screen and (min-width: 768px) {
    .attorney-profile table.table > thead > tr th {
      padding-top: 0; } }

@media screen and (min-width: 768px) {
  .attorney-profile table.table tr th:first-child, .attorney-profile table.table tr td:first-child {
    padding-left: 0; } }
