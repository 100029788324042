.verification-manager .verification-type-select .form-group {
  margin: 0; }

.verification-manager #verification-details {
  position: absolute;
  left: 50%;
  bottom: 0;
  right: 0;
  top: 52px;
  border-left: 2px solid #EEE;
  overflow-y: auto; }
  .verification-manager #verification-details .no-license-selected {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .verification-manager #verification-details .verification-overview {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 10rem;
    border-bottom: 2px solid #EEE;
    overflow-y: auto; }
  .verification-manager #verification-details .verification-details {
    position: absolute;
    top: 10rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto; }
  .verification-manager #verification-details h2 {
    margin-bottom: 0; }
  .verification-manager #verification-details h3 {
    margin-top: 0; }
    .verification-manager #verification-details h3 .label {
      display: inline-block; }

.verification-manager #verifications {
  position: absolute;
  top: 52px;
  right: 50%;
  left: 0;
  bottom: 0; }
  .verification-manager #verifications #pending-verifications {
    position: absolute;
    left: 0;
    bottom: 50%;
    top: 0;
    right: 0;
    overflow-y: auto; }
  .verification-manager #verifications #recent-verifications {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    border-top: 2px solid #EEE; }

.verification-manager table.verifications-table tbody tr {
  cursor: pointer; }
  .verification-manager table.verifications-table tbody tr.selected {
    background-color: #e2e2ef !important; }

.verification-manager table.verifications-list-table thead th {
  width: 40rem; }

.verification-manager table.verifications-list-table thead th:last-child {
  text-align: right;
  width: 1rem; }

.verification-manager table.verifications-list-table tbody td:last-child {
  text-align: right; }

.verification-manager form input, .verification-manager form select {
  max-width: 15rem; }

.verification-manager .notifications-container * {
  z-index: 1001; }
