table.messages-table td.messages-actions div {
  white-space: nowrap; }

table.messages-table td.messages-actions button {
  padding: 0px;
  margin: 0px;
  display: inline-block;
  outline: none; }

table.messages-table td.messages-actions button:focus {
  outline: none; }

.messages-form {
  margin-bottom: 5rem; }
  .messages-form.reply-message-form {
    margin-top: 3rem; }
    .messages-form.reply-message-form label.control-label {
      font-weight: bold; }
    .messages-form.reply-message-form textarea {
      height: 15rem; }
  .messages-form.create-message-form .recipients-container, .messages-form.create-invite-message-form .recipients-container {
    margin-right: 1rem; }
    .messages-form.create-message-form .recipients-container h3, .messages-form.create-invite-message-form .recipients-container h3 {
      display: inline-block;
      margin-right: 1.2rem; }
    .messages-form.create-message-form .recipients-container a, .messages-form.create-invite-message-form .recipients-container a {
      vertical-align: super;
      margin-right: .6rem;
      border-radius: 30px;
      font-size: 1.3rem;
      padding: .4rem 1rem .2rem 1rem; }
  .messages-form.create-message-form form, .messages-form.create-invite-message-form form {
    margin-top: 3rem; }
  .messages-form.create-message-form textarea, .messages-form.create-invite-message-form textarea {
    height: 15rem; }
  .messages-form.create-invite-message-form div.invite-form-buttons {
    margin-top: 4rem; }
  .messages-form.create-invite-message-form div.invite-info-headers {
    margin-top: 1rem; }
    .messages-form.create-invite-message-form div.invite-info-headers .recipients-container {
      margin-right: 0; }
      .messages-form.create-invite-message-form div.invite-info-headers .recipients-container h3 {
        margin-top: 0; }
    .messages-form.create-invite-message-form div.invite-info-headers .invite-status-header {
      font-style: italic;
      float: right; }
      .messages-form.create-invite-message-form div.invite-info-headers .invite-status-header span {
        vertical-align: -webkit-baseline-middle; }
        .messages-form.create-invite-message-form div.invite-info-headers .invite-status-header span p {
          display: inline-block;
          margin: 0; }
  .messages-form.create-invite-message-form div.form-divider {
    margin: 4rem auto 3rem auto;
    border-bottom: 1px solid #ddd; }
  .messages-form.create-invite-message-form .form-group {
    margin-bottom: 0; }
  .messages-form.create-invite-message-form .message-section {
    margin: .5rem 0 2rem 0; }
    .messages-form.create-invite-message-form .message-section .subsection-first-line {
      margin-bottom: 1rem; }
    .messages-form.create-invite-message-form .message-section textarea {
      height: 12rem; }
  .messages-form label {
    font-weight: bold; }
  .messages-form .attachments-section {
    margin: 3rem 0; }
    .messages-form .attachments-section .attachments-section-label {
      margin: 1rem 0; }
    .messages-form .attachments-section .attachments-error-msg {
      text-align: center;
      color: #a94442; }

.message-header-row {
  margin-bottom: 1rem; }
  @media screen and (min-width: 992px) {
    .message-header-row {
      margin-bottom: 0.5rem; } }

.message-panels-container {
  margin: 3rem 0; }
  .message-panels-container label {
    font-weight: bold; }
