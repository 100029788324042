ul.attorney-practice-areas {
  margin: 0 0 1rem 0;
  display: inline-block; }
  @media screen and (min-width: 768px) {
    ul.attorney-practice-areas {
      margin: 0; } }
  ul.attorney-practice-areas li {
    padding: 0.4rem 1rem;
    border-radius: 2px;
    background-color: #E6E6E3;
    margin: 5px 0; }
    ul.attorney-practice-areas li a {
      font-size: 12px;
      color: #444444; }
  ul.attorney-practice-areas.list-inline li {
    margin-right: 1rem; }
  ul.attorney-practice-areas a.toggle-show-extra {
    font-size: 14px;
    font-weight: 600;
    margin-left: 1rem; }
    ul.attorney-practice-areas a.toggle-show-extra:focus {
      text-decoration: none;
      color: #47a9cf; }
