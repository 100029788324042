.table-icon, .success-check, .danger-times {
  width: 100%;
  text-align: center; }

.danger-color, .danger-times {
  color: #DC2D25; }

.success-check {
  color: #5cb85c; }

.invoices-header {
  margin-bottom: 20px; }

.invoices-list-table tbody.invoices-list-table-body td {
  vertical-align: middle; }

@media screen and (min-width: 992px) {
  .invoices-list-table tr {
    height: 4.2rem; } }
