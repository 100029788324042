.public-profile .marketing-section {
  margin: 3.5rem 0; }
  .public-profile .marketing-section i {
    color: #48aad0; }
  .public-profile .marketing-section .media-body h4.media-heading {
    font-size: 2.2rem; }
  .public-profile .marketing-section .media-body p {
    font-size: 1.8rem;
    font-weight: 100; }
  .public-profile .marketing-section .signup-btn {
    text-align: center;
    margin-top: 1.3rem; }
