.legal-document {
  margin: 5rem 0; }
  .legal-document h1 {
    margin-bottom: 3.5rem; }
  .legal-document h2 {
    margin-top: 4rem; }
  .legal-document h3 {
    font-size: 1.5rem;
    text-transform: uppercase; }
  .legal-document .header-title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem; }
    .legal-document .header-title .document-title {
      font-weight: 300;
      font-size: 3.5rem;
      margin: 0;
      line-height: inherit; }
    .legal-document .header-title .document-subtitle {
      font-size: 2.3rem;
      margin: 0;
      line-height: inherit; }
