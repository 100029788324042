.attorney-no-profile-pic {
  border-radius: 50%;
  display: block;
  position: relative;
  margin: 0 auto; }
  .attorney-no-profile-pic .profile-initials {
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.attorney-profile-picture-wrapper {
  position: relative; }

img.attorney-profile-picture {
  border-radius: 50%;
  margin: auto; }
