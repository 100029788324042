.list-filters h3, .list-filters h4, .list-filters label.title, .list-filters button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.55rem; }

.list-filters .list-filters-heading {
  padding-bottom: 15px; }

.list-filters label.title {
  color: #0898c4; }

.main-info-container {
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #CCC; }
  @media only screen and (max-width: 360px) {
    .main-info-container {
      padding: 1rem; }
      .main-info-container .panel-body {
        padding: 0px; } }

.main-info-title {
  color: #47a9cf;
  margin: 0 0 25px 0; }

.main-info-section {
  margin-top: 15px; }

.header-action-dropdown, .invoice-dropdown {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #47a9cf;
  background-color: white;
  border: 1px solid #47a9cf;
  font-weight: 500;
  font-size: 1.3rem; }
  @media screen and (min-width: 768px) {
    .header-action-dropdown, .invoice-dropdown {
      font-size: 1.2rem; } }
  .header-action-dropdown:hover, .invoice-dropdown:hover, .header-action-dropdown:focus, .invoice-dropdown:focus {
    background-color: #f3f3f3;
    color: #246f8c;
    border-color: #246f8c; }

.dropdown-button-link {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }

span.label.via-label, span.label.available-label {
  font-size: 13.5px;
  margin-left: .5rem;
  background-color: #F4C603; }

span.label.available-label {
  background-color: #5cb85c; }

.invoice-header {
  text-align: center;
  margin-top: 1rem; }
  @media screen and (min-width: 992px) {
    .invoice-header {
      text-align: center;
      padding-bottom: 1rem; }
      .invoice-header > div {
        display: inline-block;
        padding: 1.5rem 0; } }
  .invoice-header h1 {
    display: inline-block; }
  .invoice-header div.status-label {
    display: inline-block;
    margin: .8rem 0; }
    @media screen and (min-width: 992px) {
      .invoice-header div.status-label {
        margin: 0; } }
    .invoice-header div.status-label span.label {
      vertical-align: super; }
      .invoice-header div.status-label span.label.label-danger {
        font-size: 100%; }
        @media screen and (min-width: 992px) {
          .invoice-header div.status-label span.label.label-danger {
            font-size: 120%; } }
      .invoice-header div.status-label span.label.label-success {
        font-size: 90%; }
  .invoice-header div.dropdown {
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 992px) {
      .invoice-header div.dropdown {
        margin-bottom: 0; } }

.invoice-title {
  margin: auto 0; }

.invoice-dropdown {
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  margin: 1rem 0 1.8rem 0; }
  @media screen and (min-width: 992px) {
    .invoice-dropdown {
      width: 190px;
      margin: 0; } }

.invoice-table-container td:nth-of-type(even) {
  text-align: right; }
  @media screen and (min-width: 992px) {
    .invoice-table-container td:nth-of-type(even) {
      text-align: left; } }

.invoice-table-container td:nth-of-type(odd) {
  font-weight: 600; }
