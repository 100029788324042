.hae-progress-bar {
  min-height: 10rem;
  text-align: left; }
  .hae-progress-bar .checkout-wrap {
    color: #444;
    font-family: 'PT Sans Caption', sans-serif;
    margin: 2rem auto;
    max-width: 1200px;
    position: relative; }
  .hae-progress-bar ul.checkout-bar {
    padding: 0;
    z-index: 1; }
    .hae-progress-bar ul.checkout-bar li {
      color: #EEE;
      display: block;
      padding: 14px 20px 14px 80px;
      position: relative; }
      .hae-progress-bar ul.checkout-bar li:before {
        content: '';
        background: #EEE;
        border-radius: 50%;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        left: 20px;
        line-height: 3rem;
        position: absolute;
        text-align: center;
        top: 1rem;
        height: 3rem;
        width: 3rem;
        z-index: 999; }
      .hae-progress-bar ul.checkout-bar li.active {
        color: #0898c4; }
        .hae-progress-bar ul.checkout-bar li.active:before {
          background: #0898c4;
          z-index: 99999; }
      .hae-progress-bar ul.checkout-bar li.visited {
        color: #0898c4;
        z-index: 99999; }
        .hae-progress-bar ul.checkout-bar li.visited:before {
          background: #0898c4;
          z-index: 99999; }
      .hae-progress-bar ul.checkout-bar li:nth-child(1):before {
        content: ''; }
      .hae-progress-bar ul.checkout-bar li span {
        color: #AAA;
        font-size: 1.6rem;
        text-decoration: none; }
      .hae-progress-bar ul.checkout-bar li.active span {
        color: #0898c4; }
  @media all and (min-width: 800px) {
    .hae-progress-bar .checkout-bar li.active:after {
      content: '';
      height: 0.6rem;
      width: 100%;
      left: 50%;
      position: absolute;
      top: -50px;
      z-index: 0; }
    .hae-progress-bar .checkout-wrap {
      margin: 2rem auto; }
    .hae-progress-bar ul.checkout-bar {
      background-color: #EEE;
      border-radius: 0.6rem;
      height: 0.6rem;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      width: 100%; }
      .hae-progress-bar ul.checkout-bar:before, .hae-progress-bar ul.checkout-bar:after {
        background-color: #FFF;
        content: ' ';
        height: 0.6rem;
        position: absolute;
        width: 17%; }
      .hae-progress-bar ul.checkout-bar:before {
        right: 0; }
      .hae-progress-bar ul.checkout-bar:after {
        left: 0; }
      .hae-progress-bar ul.checkout-bar li {
        display: inline-block;
        margin: 35px 0 0;
        padding: 0;
        text-align: center;
        width: 33%; }
        .hae-progress-bar ul.checkout-bar li:before {
          height: 3rem;
          width: 3rem;
          left: 46%;
          line-height: 3rem;
          position: absolute;
          top: -47px;
          z-index: 99; }
        .hae-progress-bar ul.checkout-bar li.visited {
          background: none; }
          .hae-progress-bar ul.checkout-bar li.visited:after {
            background-color: #0898c4;
            content: '';
            height: 0.6rem;
            left: 50%;
            position: absolute;
            top: -35px;
            width: 100%;
            z-index: 99; } }
