@import "https://fonts.googleapis.com/css?family=Lato:300,400,400italic,500,700";
div.delete-button i.fa-times {
  color: #777777; }
  div.delete-button i.fa-times:hover {
    color: #5e5e5e;
    cursor: pointer; }

.btn-standard {
  color: #555555;
  background-color: #eeeeee;
  border-color: #c8c8c8; }
  .btn-standard:focus, .btn-standard.focus {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #888888; }
  .btn-standard:hover {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #aaaaaa; }
  .btn-standard:active, .btn-standard.active,
  .open > .btn-standard.dropdown-toggle {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #aaaaaa; }
    .btn-standard:active:hover, .btn-standard:active:focus, .btn-standard:active.focus, .btn-standard.active:hover, .btn-standard.active:focus, .btn-standard.active.focus,
    .open > .btn-standard.dropdown-toggle:hover,
    .open > .btn-standard.dropdown-toggle:focus,
    .open > .btn-standard.dropdown-toggle.focus {
      color: #555555;
      background-color: #c3c3c3;
      border-color: #888888; }
  .btn-standard:active, .btn-standard.active,
  .open > .btn-standard.dropdown-toggle {
    background-image: none; }
  .btn-standard.disabled:hover, .btn-standard.disabled:focus, .btn-standard.disabled.focus, .btn-standard[disabled]:hover, .btn-standard[disabled]:focus, .btn-standard[disabled].focus,
  fieldset[disabled] .btn-standard:hover,
  fieldset[disabled] .btn-standard:focus,
  fieldset[disabled] .btn-standard.focus {
    background-color: #eeeeee;
    border-color: #c8c8c8; }
  .btn-standard .badge {
    color: #eeeeee;
    background-color: #555555; }

.input-large input {
  height: 1.5rem;
  width: 1.5rem; }

.input-large input[type='checkbox'] {
  position: initial !important;
  margin-right: .5rem; }

.input-large label {
  font-size: 1.7rem;
  vertical-align: text-bottom; }
  .input-large label span {
    vertical-align: text-bottom; }
