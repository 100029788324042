.reviews div.filter-buttons-container {
  margin-bottom: 4rem; }
  .reviews div.filter-buttons-container #selected {
    background-color: #47a9cf;
    color: #FFF; }

.reviews .reviews-table td {
  vertical-align: middle; }

form.performance-review-form {
  margin-top: 1rem; }
  form.performance-review-form label.control-label {
    margin-bottom: 1rem; }
