.signup-container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem; }
  @media screen and (min-width: 992px) {
    .signup-container {
      margin-top: 3rem;
      margin-bottom: 3rem; } }

.signup-form-title {
  margin-bottom: 2rem;
  font-size: 2.1rem;
  font-weight: bold; }
  @media screen and (min-width: 768px) {
    .signup-form-title {
      font-size: 2.3rem; } }
  @media screen and (min-width: 992px) {
    .signup-form-title {
      font-size: 3rem;
      margin-top: 1.5rem;
      margin-bottom: 2rem; } }

.signup-form-cta {
  font-size: 1.5rem;
  line-height: 2rem; }

.signup-form-container {
  text-align: left; }

div.legal-prof-signup div.form-container {
  margin-top: 4rem; }

div.marketing-blurb-container, div.marketing-blurb-container-mobile {
  margin: 4rem 0;
  overflow: auto; }
  div.marketing-blurb-container .marketing-blurb, div.marketing-blurb-container-mobile .marketing-blurb {
    margin-bottom: 2rem; }
    div.marketing-blurb-container .marketing-blurb > div, div.marketing-blurb-container-mobile .marketing-blurb > div {
      font-weight: 600; }
    @media screen and (min-width: 992px) {
      div.marketing-blurb-container .marketing-blurb, div.marketing-blurb-container-mobile .marketing-blurb {
        margin-bottom: 0; } }
  div.marketing-blurb-container i, div.marketing-blurb-container-mobile i {
    color: #FECB00 !important;
    margin-bottom: 10px; }

div.marketing-blurb-container-mobile {
  margin: 3rem auto 2rem auto;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  max-width: 20rem; }
  div.marketing-blurb-container-mobile .media {
    margin: 0; }

.confirm-expectation-container {
  margin: 3rem auto 2rem auto; }
  @media screen and (min-width: 992px) {
    .confirm-expectation-container {
      width: 70%; } }

.signup-submit-button {
  text-align: center;
  margin-top: 1.3rem; }
  .signup-submit-button button {
    width: auto; }

.go-to-login-link {
  text-align: center;
  margin-bottom: 1rem; }

.non-attorney-link {
  font-size: 13px;
  margin-top: 2rem; }
  @media screen and (min-width: 768px) {
    .non-attorney-link {
      margin-top: 1rem; } }

.signup-complete-container {
  margin-top: 5rem; }
  .signup-complete-container h1 {
    margin-bottom: 2rem; }
  .signup-complete-container p {
    margin-bottom: 1rem; }

.signup-identity-container, .signup-complete-container {
  max-width: 100rem;
  margin: 4rem auto 0 auto; }
  .signup-identity-container h1, .signup-complete-container h1 {
    margin-bottom: 2.5rem;
    font-size: 3.5rem;
    font-weight: bold; }
  @media screen and (min-width: 992px) {
    .signup-identity-container, .signup-complete-container {
      padding: 2em; } }

.signup-identity-button, .signup-complete-container a {
  font-size: 1.3em;
  padding: 15px 0;
  width: 100%; }

div.signup-identity-container .work-status-button, div.signup-complete-container .work-status-button {
  display: table;
  margin-bottom: 1.2rem;
  min-height: 10rem;
  min-width: 25rem; }

div.signup-identity-container .button-text, div.signup-complete-container .button-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 2.5rem;
  white-space: normal;
  max-width: 28rem; }

@media screen and (min-width: 992px) {
  div.signup-container {
    margin-top: 6rem; } }

div.signup-container .legal-prof-select div.divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #ccc; }

div.signup-container .signup-path-container {
  margin: 3rem 0; }
  @media screen and (min-width: 992px) {
    div.signup-container .signup-path-container {
      margin: 6rem 0; } }
  @media screen and (min-width: 992px) {
    div.signup-container .signup-path-container h3 {
      margin-bottom: 3rem; } }

div.signup-container div.legal-prof {
  margin-top: 3rem; }
  @media screen and (min-width: 992px) {
    div.signup-container div.legal-prof {
      margin-top: 4rem; } }
  div.signup-container div.legal-prof h3 {
    font-size: 2.1rem; }
    @media screen and (min-width: 992px) {
      div.signup-container div.legal-prof h3 {
        font-size: 2.4rem; } }
  div.signup-container div.legal-prof div.legal-prof-buttons-container {
    margin-top: 2rem; }
    div.signup-container div.legal-prof div.legal-prof-buttons-container .button-container {
      margin: 1rem 0; }
    div.signup-container div.legal-prof div.legal-prof-buttons-container a {
      border-radius: 6px;
      border-width: medium;
      width: 100%;
      height: 100%;
      padding: 2rem 1.6rem;
      font-size: 2rem;
      font-weight: bold; }
      div.signup-container div.legal-prof div.legal-prof-buttons-container a .button-text-container div.button-title {
        font-size: 2.5rem;
        white-space: normal;
        text-decoration: underline; }
      div.signup-container div.legal-prof div.legal-prof-buttons-container a .button-text-container div.helper-icons {
        font-size: 1.5rem; }
        @media screen and (min-width: 992px) {
          div.signup-container div.legal-prof div.legal-prof-buttons-container a .button-text-container div.helper-icons {
            font-size: 1.4rem; } }
    div.signup-container div.legal-prof div.legal-prof-buttons-container .descriptions {
      color: #777; }
      div.signup-container div.legal-prof div.legal-prof-buttons-container .descriptions .list-container {
        display: inline-block;
        margin: 0 auto 0 auto; }
        div.signup-container div.legal-prof div.legal-prof-buttons-container .descriptions .list-container ul {
          text-align: left; }
    div.signup-container div.legal-prof div.legal-prof-buttons-container .legal-prof-button-container {
      border: 1px solid #EEE;
      border-radius: 0.5rem;
      margin-bottom: 2rem; }
      div.signup-container div.legal-prof div.legal-prof-buttons-container .legal-prof-button-container div {
        margin: 1.5rem; }
      div.signup-container div.legal-prof div.legal-prof-buttons-container .legal-prof-button-container a {
        white-space: normal; }

div.signup-container .type-buttons-container, div.signup-container .non-att-type div.non-att-type-buttons, div.signup-container .non-att-subtype div.non-att-subtype-buttons {
  margin-top: 3rem;
  margin-bottom: 4rem; }
  @media screen and (min-width: 992px) {
    div.signup-container .type-buttons-container, div.signup-container .non-att-type div.non-att-type-buttons, div.signup-container .non-att-subtype div.non-att-subtype-buttons {
      margin-bottom: 8rem; } }

div.signup-container .type-buttons, div.signup-container .non-att-type div.non-att-type-buttons button, div.signup-container .non-att-subtype div.non-att-subtype-buttons button {
  min-height: 9rem;
  font-size: 1.8rem;
  min-width: 100%;
  border-radius: 6px;
  border-width: medium;
  white-space: normal;
  margin: .3rem 0; }

div.signup-container .non-att-subtype .nav-buttons .back-button {
  padding-left: 0; }

div.signup-container .non-att-subtype div.non-att-subtype-buttons button {
  position: relative; }

div.signup-container .non-att-subtype div.non-att-subtype-buttons button.selected-subtype {
  background-color: #367c36;
  border-color: #2d682d; }

div.signup-container .non-att-subtype div.non-att-subtype-buttons .success-icon i {
  position: absolute;
  top: 8px;
  right: 10px; }

.react-base-public.signup {
  background-image: url("../../img/skyline-1280p-25o.jpg");
  background-size: cover;
  background-position: center; }
