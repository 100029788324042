@import "https://fonts.googleapis.com/css?family=Lato:300,400,400italic,500,700";
.contractor-navbar {
  border: none !important;
  z-index: 2 !important; }
  .contractor-navbar ul.navbar-nav > li > a {
    text-transform: uppercase;
    font-weight: 600; }
    @media screen and (min-width: 768px) {
      .contractor-navbar ul.navbar-nav > li > a:hover {
        border-bottom: 3px solid #00C6D7;
        padding-bottom: 12px; } }

.contractor-jobs-panel-group .panel-heading {
  cursor: pointer; }

.contractor-jobs-panel-group .job-offer-expire-warning {
  float: left; }
  @media screen and (min-width: 768px) {
    .contractor-jobs-panel-group .job-offer-expire-warning {
      float: right; } }

.contractor-jobs-panel-group ul.list-inline > li {
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .contractor-jobs-panel-group ul.list-inline > li {
      margin-bottom: 0; } }

.contractor-messages-preview tr.unread > * {
  font-weight: bold; }

.job-search-result .job-attributes {
  margin-bottom: 2rem; }

.job-search-result .expertise-areas {
  margin-bottom: 1rem; }
  .job-search-result .expertise-areas .expertise-area {
    padding: 2px 8px;
    background-color: #EEE;
    border-radius: 2px;
    font-size: 12px;
    margin-bottom: 5px;
    display: inline-block; }

.badge.important {
  background-color: #DC2D25; }

.message-icon .badge {
  margin-left: 0.25rem; }
  @media screen and (min-width: 375px) {
    .message-icon .badge {
      margin-left: 0;
      position: absolute;
      right: 0;
      top: 2.5rem; } }

.messages-tray .popover-content {
  padding: 0; }

.messages-tray .messages-tray-list {
  margin: 0; }
  .messages-tray .messages-tray-list li {
    padding: 1rem;
    margin: 0;
    border-bottom: 1px solid #EEE;
    min-width: 20rem; }
    .messages-tray .messages-tray-list li:last-child {
      border-bottom: none; }
    .messages-tray .messages-tray-list li:hover, .messages-tray .messages-tray-list li:focus {
      background-color: #EEE; }
  .messages-tray .messages-tray-list a.message-preview-link {
    color: #000; }
    .messages-tray .messages-tray-list a.message-preview-link:hover, .messages-tray .messages-tray-list a.message-preview-link:focus {
      text-decoration: none; }

.application-requirements .checkbox {
  margin: 0; }
  .application-requirements .checkbox .form-group {
    margin: 0; }
