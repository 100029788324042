.applications-dashboard .react-datepicker__input-container {
  display: block; }

.applications-dashboard .application {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #DDD;
  border-radius: 2px;
  font-size: 1.25rem; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }
