.survey-common {
  margin: 1rem 0 3rem 0; }
  .survey-common h1 {
    margin: 1.5rem 0 1rem 0;
    font-size: 2.8rem; }
    @media screen and (min-width: 768px) {
      .survey-common h1 {
        margin: 3rem 0 1rem 0;
        font-size: 3rem; } }
  .survey-common .survey-form-description-common {
    margin: 2rem 0 3rem 0; }
    @media screen and (min-width: 768px) {
      .survey-common .survey-form-description-common {
        margin: 4rem 0 3.5rem 0; } }
  .survey-common .form-group {
    margin-bottom: 3rem; }
    .survey-common .form-group label.control-label {
      margin-bottom: 1rem; }
