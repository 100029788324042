.bubble-input {
  padding: 0.5rem 1.25rem;
  border: 1px solid #d4ebef;
  height: 32px;
  border-radius: 16px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 0.5rem; }
  .bubble-input.active {
    background-color: #d4ebef; }
  .bubble-input:not(.active):hover {
    background-color: #f2f9fa; }
  .bubble-input:last-child {
    margin-right: 0; }
