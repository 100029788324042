.jobs-marketing i, div.job-marketing-footer.jobs-marketing i {
  color: #48aad0; }

div.job-marketing-footer {
  margin: 3rem 0; }
  div.job-marketing-footer p.lead {
    font-weight: bold;
    margin-bottom: 3rem; }
  div.job-marketing-footer .footer-title {
    margin-bottom: 2rem; }
    @media screen and (min-width: 480px) {
      div.job-marketing-footer .footer-title {
        margin-bottom: 0; } }
    @media screen and (min-width: 480px) {
      div.job-marketing-footer .footer-title h2 {
        margin-top: 0;
        float: left;
        display: inline-block; } }
    @media screen and (min-width: 480px) {
      div.job-marketing-footer .footer-title .btn {
        float: right; } }

.public-job h1.header {
  text-align: center; }
  @media screen and (min-width: 480px) {
    .public-job h1.header {
      text-align: initial; } }
  .public-job h1.header .job-title {
    display: inline-block; }
    @media screen and (min-width: 480px) {
      .public-job h1.header .job-title {
        float: left;
        display: inline-block; } }
  .public-job h1.header .cta-button-container {
    margin-top: 1.2rem;
    display: inline-block;
    width: 100%; }
    .public-job h1.header .cta-button-container a {
      width: 100%; }
    @media screen and (min-width: 480px) {
      .public-job h1.header .cta-button-container {
        float: right;
        margin: 0;
        width: auto; } }

.public-job p.header-copy {
  font-weight: 300;
  font-size: 2.1rem; }

.public-job-search .job-search-marketing {
  margin-bottom: 0; }
  @media screen and (min-width: 480px) {
    .public-job-search .job-search-marketing {
      margin-bottom: 3rem; } }
  @media screen and (min-width: 480px) {
    .public-job-search .job-search-marketing .header {
      margin-bottom: 2rem; } }
  .public-job-search .job-search-marketing .header h1 {
    margin-top: 1rem; }
