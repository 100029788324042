body {
  overflow-y: scroll; }

@media only screen and (min-width: 500px) {
  .container {
    padding-left: 15px;
    padding-right: 15px; } }

#search-bar-container {
  margin-top: 24.84472px; }
  @media only screen and (min-width: 500px) {
    #search-bar-container {
      margin-top: 40px; } }

.search-bar #search-input {
  position: relative; }
  .search-bar #search-input label {
    position: absolute;
    top: 11px;
    left: 15.4315px;
    color: #808080; }
  .search-bar #search-input input[type="search"] {
    padding-left: 40px;
    height: 42px; }
  .search-bar #search-input .clear-search {
    position: absolute;
    top: 10px;
    right: 15.4315px;
    color: #808080;
    font-size: 18px;
    cursor: pointer; }

.search-bar input[type="submit"] {
  height: 42px;
  font-size: 16px;
  font-weight: 600; }

.search-results-container {
  min-height: 300px;
  margin-top: 15.4315px;
  margin-bottom: 24.84472px;
  background-color: #FFFFFF; }
  @media only screen and (min-width: 500px) {
    .search-results-container {
      margin-top: 40px;
      margin-bottom: 40px; } }
  .search-results-container .search-results-filters-container {
    padding: 10px;
    border-bottom: 1px solid rgba(181, 181, 181, 0.5);
    position: relative; }
    @media only screen and (min-width: 500px) {
      .search-results-container .search-results-filters-container {
        padding: 40px;
        min-height: 150px; } }
    .search-results-container .search-results-filters-container .form-group, .search-results-container .search-results-filters-container .checkbox {
      margin: 0 0 10px 0;
      height: 40px;
      line-height: 40px; }
      @media only screen and (min-width: 500px) {
        .search-results-container .search-results-filters-container .form-group, .search-results-container .search-results-filters-container .checkbox {
          margin: 0 10px 10px 0; } }
    .search-results-container .search-results-filters-container .checkbox label {
      padding-left: 0; }
    .search-results-container .search-results-filters-container .checkbox input {
      position: relative;
      margin-left: 0; }
    .search-results-container .search-results-filters-container select, .search-results-container .search-results-filters-container input[type="text"], .search-results-container .search-results-filters-container .Select {
      height: 100%;
      width: 100%; }
      @media only screen and (min-width: 500px) {
        .search-results-container .search-results-filters-container select, .search-results-container .search-results-filters-container input[type="text"], .search-results-container .search-results-filters-container .Select {
          width: 210px; } }
    .search-results-container .search-results-filters-container input[type="checkbox"] {
      margin-right: 10px; }
    .search-results-container .search-results-filters-container .show-hide-filters-container {
      display: block; }
      @media only screen and (min-width: 500px) {
        .search-results-container .search-results-filters-container .show-hide-filters-container {
          display: none; } }
    .search-results-container .search-results-filters-container .number-of-results {
      display: block;
      position: absolute;
      left: 10px;
      bottom: 0; }
      @media only screen and (min-width: 500px) {
        .search-results-container .search-results-filters-container .number-of-results {
          left: 40px;
          bottom: 0; } }
  .search-results-container .search-results-list-container {
    padding-bottom: 10px; }
    @media only screen and (min-width: 500px) {
      .search-results-container .search-results-list-container {
        padding-bottom: 40px; } }
    .search-results-container .search-results-list-container .search-results-list {
      margin: 0; }
  .search-results-container .next-page-btn-container {
    padding-top: 10px; }
    @media only screen and (min-width: 500px) {
      .search-results-container .next-page-btn-container {
        padding-top: 40px; } }
  .search-results-container .preview-alert-container {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(181, 181, 181, 0.5); }
    @media only screen and (min-width: 500px) {
      .search-results-container .preview-alert-container {
        padding: 3rem 0; } }

.no-search-results {
  text-align: center;
  padding-top: 15.4315px; }
  @media only screen and (min-width: 500px) {
    .no-search-results {
      padding-top: 40px; } }

.search-result {
  display: block;
  border-bottom: 1px solid rgba(181, 181, 181, 0.5);
  position: relative; }
  .search-result article {
    padding: 24.84472px 10px;
    position: relative;
    min-height: 250px; }
    @media only screen and (min-width: 500px) {
      .search-result article {
        padding: 40px; } }
    .search-result article.via-attorney {
      background-color: #fef3c5; }
  .search-result:last-child {
    min-height: 170px;
    padding-bottom: 0; }
  .search-result .attorney-profile-snippet {
    overflow: hidden; }
    .search-result .attorney-profile-snippet .search-result-title-labels {
      display: block;
      margin-bottom: 1rem; }
      .search-result .attorney-profile-snippet .search-result-title-labels span {
        margin-left: 0;
        margin-right: .5rem; }
      @media only screen and (min-width: 1200px) {
        .search-result .attorney-profile-snippet .search-result-title-labels {
          margin-left: 1rem;
          margin-bottom: 0;
          display: inline-block; } }
    .search-result .attorney-profile-snippet h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 15.4315px 0; }
      @media only screen and (min-width: 500px) {
        .search-result .attorney-profile-snippet h2 {
          margin-top: 0; } }
      .search-result .attorney-profile-snippet h2 .search-result-attorney-name {
        vertical-align: middle; }
    .search-result .attorney-profile-snippet h3 {
      font-size: 18px;
      color: #444444;
      margin: 0 0 15.4315px 0; }
    .search-result .attorney-profile-snippet .location {
      font-size: 14px;
      color: #808080;
      margin: 0 0 15.4315px 0;
      display: block; }
      .search-result .attorney-profile-snippet .location i {
        margin-right: 10px; }
    .search-result .attorney-profile-snippet p {
      margin: 0 0 15.4315px 0;
      font-size: 14px;
      color: #444444; }
  .search-result .extra-info {
    padding: 0 15px;
    margin-top: 15.4315px; }
  .search-result a.view-profile {
    padding: 6px 20px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px; }
  .search-result .hide-attorney {
    margin-left: 24.84472px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: block; }
    .search-result .hide-attorney i {
      vertical-align: top;
      color: rgba(181, 181, 181, 0.5);
      font-size: 18px; }
  .search-result .years-experience {
    font-size: 14px;
    color: #444444; }
  .search-result .muted {
    color: #808080; }
  .search-result em {
    font-style: normal;
    background-color: #f5ef9d; }
  .search-result .search-display_score .rating-container {
    text-align: inherit;
    color: #47a9cf;
    font-size: 1.4rem; }
    .search-result .search-display_score .rating-container .rating {
      font-size: inherit;
      margin: 0 0.3rem 0 0;
      pointer-events: none; }

.talentpools-button {
  display: block;
  min-width: 130px;
  color: #808080;
  margin-top: 15.4315px;
  text-align: center; }
  @media only screen and (min-width: 500px) {
    .talentpools-button {
      margin-top: 24.84472px; } }
  .talentpools-button:hover, .talentpools-button:focus {
    text-decoration: none;
    color: #808080; }
  .talentpools-button i {
    margin-right: 10px;
    color: #5cb85c;
    font-size: 18px; }
  .talentpools-button.added {
    color: #5cb85c; }

.search-badges {
  margin-top: 15.4315px; }

.search-badge {
  color: #808080;
  margin-right: 20px;
  display: inline-block; }

.search-results-transition-enter {
  opacity: 0.01; }

.search-results-transition-enter.search-results-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.search-results-transition-leave {
  opacity: 1; }

.search-results-transition-leave.search-results-transition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.geosuggest-container {
  position: relative; }
  .geosuggest-container .clear-location {
    position: absolute;
    top: -2px;
    right: 8px;
    color: #808080;
    font-size: 18px;
    cursor: pointer; }

.attorney-profile-picture-container {
  text-align: center; }
  @media only screen and (min-width: 500px) {
    .attorney-profile-picture-container {
      float: left;
      margin-right: 40px; } }
