.react-dropzone-input .react-dropzone-container {
  margin: 0; }
  .react-dropzone-input .react-dropzone-container .dropzone {
    border: 3px dashed #DDD;
    border-radius: 10px;
    cursor: pointer;
    padding: 1.8rem;
    color: #47a9cf; }
  .react-dropzone-input .react-dropzone-container .active-dropzone {
    border: 2px dashed #47a9cf;
    border-radius: 20px;
    color: #47a9cf; }
  .react-dropzone-input .react-dropzone-container .dropzone-text-container {
    text-align: center; }
    .react-dropzone-input .react-dropzone-container .dropzone-text-container i {
      color: #FECB00;
      margin-right: 3px; }
