.list-filters h3, .list-filters h4, .list-filters label.title, .list-filters button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.55rem; }

.list-filters .list-filters-heading {
  padding-bottom: 15px; }

.list-filters label.title {
  color: #0898c4; }

.main-info-container {
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #CCC; }
  @media only screen and (max-width: 360px) {
    .main-info-container {
      padding: 1rem; }
      .main-info-container .panel-body {
        padding: 0px; } }

.main-info-title, .candidates-list-page .status-section h4 {
  color: #47a9cf;
  margin: 0 0 25px 0; }

.main-info-section {
  margin-top: 15px; }

.header-action-dropdown {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #47a9cf;
  background-color: white;
  border: 1px solid #47a9cf;
  font-weight: 500;
  font-size: 1.3rem; }
  @media screen and (min-width: 768px) {
    .header-action-dropdown {
      font-size: 1.2rem; } }
  .header-action-dropdown:hover, .header-action-dropdown:focus {
    background-color: #f3f3f3;
    color: #246f8c;
    border-color: #246f8c; }

.dropdown-button-link {
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap; }

.form-control-react-datepicker .react-datepicker__input-container {
  width: 100% !important;
  display: table !important; }
  .form-control-react-datepicker .react-datepicker__input-container input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }

span.label.via-label, span.label.available-label {
  font-size: 13.5px;
  margin-left: .5rem;
  background-color: #F4C603; }

span.label.available-label {
  background-color: #5cb85c; }

.candidates-list-page .status-filter {
  margin-top: 2.5rem; }
  @media screen and (min-width: 992px) {
    .candidates-list-page .status-filter {
      margin-top: 0; } }

.candidates-list-page .status-section {
  margin: 2rem 0; }
  .candidates-list-page .status-section h4 {
    text-transform: capitalize;
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 2rem; }
  @media screen and (min-width: 992px) {
    .candidates-list-page .status-section table th:nth-of-type(1) {
      width: 20%; }
    .candidates-list-page .status-section table th:nth-of-type(2) {
      width: 60%; } }
  .candidates-list-page .status-section table tbody tr:hover {
    cursor: pointer; }
  .candidates-list-page .status-section .no-content-message {
    margin: 0;
    font-size: 1.4rem; }
