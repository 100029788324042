@import "https://fonts.googleapis.com/css?family=Lato:300,400,400italic,500,700";
div.delete-button i.fa-times {
  color: #777777; }
  div.delete-button i.fa-times:hover {
    color: #5e5e5e;
    cursor: pointer; }

.btn-standard, div.settings .edit-job-notifications .subscription-form .inactivate-button {
  color: #555555;
  background-color: #eeeeee;
  border-color: #c8c8c8; }
  .btn-standard:focus, div.settings .edit-job-notifications .subscription-form .inactivate-button:focus, .btn-standard.focus, div.settings .edit-job-notifications .subscription-form .focus.inactivate-button {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #888888; }
  .btn-standard:hover, div.settings .edit-job-notifications .subscription-form .inactivate-button:hover {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #aaaaaa; }
  .btn-standard:active, div.settings .edit-job-notifications .subscription-form .inactivate-button:active, .btn-standard.active, div.settings .edit-job-notifications .subscription-form .active.inactivate-button,
  .open > .btn-standard.dropdown-toggle,
  div.settings .edit-job-notifications .subscription-form .open > .dropdown-toggle.inactivate-button {
    color: #555555;
    background-color: #d5d5d5;
    border-color: #aaaaaa; }
    .btn-standard:active:hover, div.settings .edit-job-notifications .subscription-form .inactivate-button:active:hover, .btn-standard:active:focus, div.settings .edit-job-notifications .subscription-form .inactivate-button:active:focus, .btn-standard:active.focus, div.settings .edit-job-notifications .subscription-form .inactivate-button:active.focus, .btn-standard.active:hover, div.settings .edit-job-notifications .subscription-form .active.inactivate-button:hover, .btn-standard.active:focus, div.settings .edit-job-notifications .subscription-form .active.inactivate-button:focus, .btn-standard.active.focus, div.settings .edit-job-notifications .subscription-form .active.focus.inactivate-button,
    .open > .btn-standard.dropdown-toggle:hover,
    div.settings .edit-job-notifications .subscription-form .open > .dropdown-toggle.inactivate-button:hover,
    .open > .btn-standard.dropdown-toggle:focus,
    div.settings .edit-job-notifications .subscription-form .open > .dropdown-toggle.inactivate-button:focus,
    .open > .btn-standard.dropdown-toggle.focus,
    div.settings .edit-job-notifications .subscription-form .open > .dropdown-toggle.focus.inactivate-button {
      color: #555555;
      background-color: #c3c3c3;
      border-color: #888888; }
  .btn-standard:active, div.settings .edit-job-notifications .subscription-form .inactivate-button:active, .btn-standard.active, div.settings .edit-job-notifications .subscription-form .active.inactivate-button,
  .open > .btn-standard.dropdown-toggle,
  div.settings .edit-job-notifications .subscription-form .open > .dropdown-toggle.inactivate-button {
    background-image: none; }
  .btn-standard.disabled:hover, div.settings .edit-job-notifications .subscription-form .disabled.inactivate-button:hover, .btn-standard.disabled:focus, div.settings .edit-job-notifications .subscription-form .disabled.inactivate-button:focus, .btn-standard.disabled.focus, div.settings .edit-job-notifications .subscription-form .disabled.focus.inactivate-button, .btn-standard[disabled]:hover, div.settings .edit-job-notifications .subscription-form .inactivate-button[disabled]:hover, .btn-standard[disabled]:focus, div.settings .edit-job-notifications .subscription-form .inactivate-button[disabled]:focus, .btn-standard[disabled].focus, div.settings .edit-job-notifications .subscription-form .inactivate-button[disabled].focus,
  fieldset[disabled] .btn-standard:hover,
  fieldset[disabled] div.settings .edit-job-notifications .subscription-form .inactivate-button:hover,
  div.settings .edit-job-notifications .subscription-form fieldset[disabled] .inactivate-button:hover,
  fieldset[disabled] .btn-standard:focus,
  fieldset[disabled] div.settings .edit-job-notifications .subscription-form .inactivate-button:focus,
  div.settings .edit-job-notifications .subscription-form fieldset[disabled] .inactivate-button:focus,
  fieldset[disabled] .btn-standard.focus,
  fieldset[disabled] div.settings .edit-job-notifications .subscription-form .focus.inactivate-button,
  div.settings .edit-job-notifications .subscription-form fieldset[disabled] .focus.inactivate-button {
    background-color: #eeeeee;
    border-color: #c8c8c8; }
  .btn-standard .badge, div.settings .edit-job-notifications .subscription-form .inactivate-button .badge {
    color: #eeeeee;
    background-color: #555555; }

.input-large input {
  height: 1.5rem;
  width: 1.5rem; }

.input-large input[type='checkbox'] {
  position: initial !important;
  margin-right: .5rem; }

.input-large label {
  font-size: 1.7rem;
  vertical-align: text-bottom; }
  .input-large label span {
    vertical-align: text-bottom; }

div.edit-profile h1, div.settings h1 {
  margin: 1.5rem 0;
  font-size: 2.6rem; }
  @media screen and (min-width: 768px) {
    div.edit-profile h1, div.settings h1 {
      font-size: 3rem; } }

div.edit-profile h2.edit-section-title, div.settings h2.edit-section-title {
  margin-bottom: 2.3rem;
  margin-top: 0; }

div.edit-profile .references th, div.settings .references th {
  width: 10rem; }

div.settings .edit-job-notifications .header-section {
  display: none; }
  @media screen and (min-width: 768px) {
    div.settings .edit-job-notifications .header-section {
      display: inherit; } }

div.settings .edit-job-notifications h2.edit-section-title {
  margin-bottom: 1rem; }

div.settings .edit-job-notifications .settings-page-section {
  margin-top: 2.9rem; }

div.settings .edit-job-notifications .subscription-form .status-text {
  margin-top: 1rem; }
  @media screen and (min-width: 768px) {
    div.settings .edit-job-notifications .subscription-form .status-text {
      margin-top: 0;
      display: inline-block; } }

div.settings .edit-job-notifications .subscription-form .subscription-actions button {
  width: 100%; }
  @media screen and (min-width: 768px) {
    div.settings .edit-job-notifications .subscription-form .subscription-actions button {
      width: initial; } }

div.settings .edit-job-notifications .active-preferences-form .disabling-content {
  position: relative;
  opacity: 0.5; }

div.settings .edit-job-notifications .active-preferences-form .disabling-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 1; }

div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group {
  margin-bottom: .7rem; }
  @media screen and (min-width: 768px) {
    div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group {
      margin-bottom: 15px; } }
  div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel {
    margin-bottom: 0; }
    div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel.hasError {
      border-color: #a94442; }
      div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel.hasError .badge {
        background-color: #a94442; }
      div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel.hasError .selected-tags-count {
        color: #a94442; }
    div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-heading {
      position: relative;
      text-align: center; }
      div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-heading .select-all-checkbox {
        position: absolute;
        left: 0;
        padding-left: inherit;
        padding-right: 5px; }
      div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-heading .panel-title {
        text-transform: uppercase;
        font-size: 1.5rem; }
        div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-heading .panel-title .badge {
          background-color: #777777;
          font-size: x-small;
          vertical-align: text-bottom; }
    div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-body {
      overflow: auto;
      height: 18rem; }
      @media screen and (min-width: 768px) {
        div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-body {
          padding-top: 2px; } }
      div.settings .edit-job-notifications .active-preferences-form .expertise-areas-section .form-group .expertise-areas-panel .panel-body .selected-tags-count {
        padding-bottom: 5px; }

div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .form-group {
  margin-bottom: 10px; }

div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel {
  margin-bottom: 10px; }
  div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .panel-heading {
    text-align: center; }
  div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .panel-title {
    text-transform: uppercase;
    display: flex;
    flex-direction: row; }
    div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .panel-title div.location-title {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%; }
  div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .location-range-input .btn-toolbar {
    margin-left: 0; }
    div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .location-range-input .btn-toolbar .btn-group {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 0; }
      div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .location-range-input .btn-toolbar .btn-group label {
        width: 100%; }
      div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .location-range-input .btn-toolbar .btn-group label:first-of-type {
        border-top-left-radius: 0; }
      div.settings .edit-job-notifications .active-preferences-form .locations-section .city-input-fields .panel .location-range-input .btn-toolbar .btn-group label:last-of-type {
        border-top-right-radius: 0; }

@media screen and (min-width: 768px) {
  div.settings-unsubscribe-modal .field-container {
    padding: .5rem 2rem; } }

div.settings-unsubscribe-modal .field-container .settings-radio-field .form-group {
  margin-bottom: 0; }

div.settings-unsubscribe-modal .field-container .settings-radio-field label {
  margin-top: 1rem; }

div.settings-unsubscribe-modal .field-container .paused-until-input {
  margin-left: 2rem; }
  @media screen and (min-width: 768px) {
    div.settings-unsubscribe-modal .field-container .paused-until-input {
      width: 50%; } }
