/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  position: relative;
  text-align: left; }

.geosuggest__input {
  display: block;
  width: 100%;
  height: 35px;
  padding: 6px 24px 6px 12px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.geosuggest__input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 0 0 4px 4px;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s; }

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0; }

/**
 * A geosuggest item
 */
.geosuggest__item {
  padding: .5em .65em;
  cursor: pointer; }

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5; }

.geosuggest__item--active {
  background: #267dc0;
  color: #fff; }

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc; }

.geosuggest__item__matched-text {
  font-weight: bold; }
