.job-search-results .number-of-results {
  margin-bottom: 1rem;
  display: block; }
  @media screen and (min-width: 480px) {
    .job-search-results .number-of-results {
      float: left;
      margin-bottom: 0;
      display: inline-block; } }

@media screen and (min-width: 480px) {
  .job-search-results .sorting-options {
    float: right; } }

.job-details-row div:nth-child(1) {
  color: #777;
  font-weight: bold; }
  @media screen and (min-width: 375px) {
    .job-details-row div:nth-child(1) {
      float: left; } }

@media screen and (min-width: 375px) {
  .job-details-row div:nth-child(2) {
    float: right; } }
