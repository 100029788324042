.disabler {
  z-index: 1000;
  /* make it cover the whole screen */
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* use default bootstrap modal backdrop */
  background-color: black;
  opacity: .5;
  filter: alpha(opacity=0); }

#onboarding-popover-cta {
  z-index: 1005;
  transition: opacity 300ms ease-in; }
  @media screen and (min-width: 768px) {
    #onboarding-popover-cta {
      position: fixed; } }

#agency-onboarding-modal .secondary-text, #agency-onboarding-modal .modal-body .select-all-text {
  margin: 1.5rem 0;
  color: #777777;
  font-size: 2.5rem;
  font-weight: 300;
  text-align: center; }

#agency-onboarding-modal .modal-dialog {
  width: 750px; }

#agency-onboarding-modal .modal-header {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  border-bottom: none;
  text-align: center; }
  #agency-onboarding-modal .modal-header > div {
    margin-top: 2rem; }
  #agency-onboarding-modal .modal-header .title {
    font-size: 3.5rem;
    color: #47a9cf; }

#agency-onboarding-modal .modal-body {
  padding-left: 3rem;
  padding-right: 3rem;
  max-height: 58vh;
  overflow-y: auto; }
  #agency-onboarding-modal .modal-body .select-all-text {
    font-size: 1.8rem; }

#agency-onboarding-modal .modal-footer {
  border-top: none; }
  #agency-onboarding-modal .modal-footer i {
    font-size: 3.3rem; }

#agency-onboarding-modal button.needs-select-button, #agency-onboarding-modal button.needs-select-button.tags-group {
  margin-bottom: 1rem;
  white-space: normal;
  width: 100%;
  font-size: medium;
  font-weight: 600;
  height: 6rem;
  color: #5bc0de;
  border: 2px solid #5bc0de; }
  #agency-onboarding-modal button.needs-select-button.selected {
    color: white; }
  #agency-onboarding-modal button.tags-group.needs-select-button {
    font-size: small; }

#agency-onboarding-modal .onboarding-welcome-page .title {
  font-size: 4.2rem; }

#agency-onboarding-modal .onboarding-welcome-page .secondary-text, #agency-onboarding-modal .onboarding-welcome-page .modal-body .select-all-text, #agency-onboarding-modal .modal-body .onboarding-welcome-page .select-all-text {
  font-size: 2.8rem; }

#agency-onboarding-modal .onboarding-welcome-page .modal-footer {
  background: #47a9cf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  #agency-onboarding-modal .onboarding-welcome-page .modal-footer .job-question-text {
    color: white;
    margin-top: 2.5rem; }
  #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container {
    margin: 8rem 0 .5rem 0; }
    #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container button {
      color: #a3e4f8;
      float: left; }
    #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container a {
      color: white;
      float: right; }

@media screen and (max-width: 480px) {
  #agency-onboarding-modal {
    padding-left: 0; }
    #agency-onboarding-modal .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0; }
    #agency-onboarding-modal .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0; }
    #agency-onboarding-modal .modal-footer {
      margin-bottom: 6rem; }
      #agency-onboarding-modal .modal-footer button.tags-group {
        height: 10rem; }
    #agency-onboarding-modal .onboarding-welcome-page .modal-footer {
      background: none;
      border-bottom: none; }
      #agency-onboarding-modal .onboarding-welcome-page .modal-footer .job-question-text {
        color: #777777;
        margin-top: 2.5rem; }
      #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container {
        margin: 5rem 0 .5rem 0; }
        #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container a, #agency-onboarding-modal .onboarding-welcome-page .modal-footer .welcome-nav-container button {
          color: #47a9cf;
          border: 2px solid #47a9cf;
          min-width: 80%;
          margin: .8rem auto;
          text-align: center;
          display: inline-block;
          float: none; } }

div.needs-profile-progress-bar {
  text-align: center; }
  div.needs-profile-progress-bar div {
    border-radius: 50%;
    margin: 0 .3rem;
    width: 1.1rem;
    height: 1.1rem;
    display: inline-block;
    background-color: #ddd; }
    div.needs-profile-progress-bar div.current {
      background-color: #47a9cf; }
