.transition-opacity-enter, .transition-opacity-replace-enter {
  opacity: 0.01; }
  .transition-opacity-enter.transition-opacity-enter-active, .transition-opacity-enter-active.transition-opacity-replace-enter, .transition-opacity-replace-enter.transition-opacity-replace-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out; }

.transition-opacity-leave, .transition-opacity-replace-leave {
  opacity: 1; }
  .transition-opacity-leave.transition-opacity-leave-active, .transition-opacity-leave-active.transition-opacity-replace-leave, .transition-opacity-replace-leave.transition-opacity-replace-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in-out; }

.transition-opacity-replace-enter.transition-opacity-replace-enter-active {
  transition: opacity 300ms ease-in-out 200ms; }

.transition-slide-opacity-enter {
  opacity: 0.01;
  max-height: 0;
  overflow: hidden; }
  .transition-slide-opacity-enter.transition-slide-opacity-enter-active {
    opacity: 1;
    transition: max-height 300ms linear, opacity 300ms ease-in-out;
    max-height: 104px; }

.transition-slide-opacity-leave {
  opacity: 1;
  max-height: 104px;
  overflow: hidden; }
  .transition-slide-opacity-leave.transition-slide-opacity-leave-active {
    opacity: 0.01;
    transition: max-height 200ms linear, opacity 200ms ease-in-out;
    max-height: 0; }
