.attorney-snippet .favorite {
  margin-top: 1rem;
  color: #5cb85c; }
  .attorney-snippet .favorite a {
    color: #5cb85c; }

.attorney-snippet .line-item {
  margin-bottom: 0.5rem; }

.attorney-snippet .location {
  color: #808080; }
  .attorney-snippet .location i {
    margin-right: 1rem; }
