.post-job .years-experience-container {
  padding: 0 1rem;
  margin: 1.5rem 0 4rem 0; }
  .post-job .years-experience-container .rc-slider-step {
    display: none; }

.post-job .recommended-attorneys {
  background-color: #F8F8F8;
  border-radius: 4px;
  min-height: 4rem;
  padding: 2rem;
  margin: 0 0 2rem 0; }
  .post-job .recommended-attorneys .attorney-summary {
    color: #666; }

.post-job .recommendation-description {
  color: #666;
  font-size: 1.6rem;
  margin-bottom: 2rem; }

.post-job .options-container {
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  padding: 1rem 2rem 0 2rem;
  margin-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .post-job .options-container {
      margin-bottom: 0; } }

.post-job .create-job-success h2 {
  color: #0898c4; }

.post-job .create-job-success p {
  color: #666; }

.post-job .form-group {
  margin-bottom: 2rem; }

.create-job-progress {
  min-height: 5rem; }
  .create-job-progress .checkout-wrap {
    color: #444;
    font-family: 'PT Sans Caption', sans-serif;
    margin: 2rem auto;
    max-width: 1200px;
    position: relative; }
  .create-job-progress ul.checkout-bar {
    padding: 0; }
    .create-job-progress ul.checkout-bar li {
      color: #EEE;
      display: block;
      padding: 14px 20px 14px 80px;
      position: relative; }
      .create-job-progress ul.checkout-bar li:before {
        content: '';
        background: #EEE;
        border-radius: 50%;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        left: 20px;
        line-height: 3rem;
        position: absolute;
        text-align: center;
        top: 1rem;
        height: 3rem;
        width: 3rem; }
      .create-job-progress ul.checkout-bar li.active {
        color: #0898c4; }
        .create-job-progress ul.checkout-bar li.active:before {
          background: #0898c4; }
      .create-job-progress ul.checkout-bar li.visited {
        color: #0898c4; }
        .create-job-progress ul.checkout-bar li.visited:before {
          background: #0898c4; }
      .create-job-progress ul.checkout-bar li:nth-child(1):before {
        content: ''; }
      .create-job-progress ul.checkout-bar li span {
        color: #AAA;
        font-size: 1.6rem;
        text-decoration: none; }
      .create-job-progress ul.checkout-bar li.active span {
        color: #0898c4; }
  @media all and (min-width: 800px) {
    .create-job-progress .checkout-bar li.active:after {
      content: '';
      height: 0.6rem;
      width: 100%;
      left: 50%;
      position: absolute;
      top: -50px;
      z-index: 0; }
    .create-job-progress .checkout-wrap {
      margin: 2rem auto; }
    .create-job-progress ul.checkout-bar {
      background-color: #EEE;
      border-radius: 0.6rem;
      height: 0.6rem;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      width: 100%; }
      .create-job-progress ul.checkout-bar:before, .create-job-progress ul.checkout-bar:after {
        background-color: #FFF;
        content: ' ';
        height: 0.6rem;
        position: absolute;
        width: 13%; }
      .create-job-progress ul.checkout-bar:before {
        right: 0; }
      .create-job-progress ul.checkout-bar:after {
        left: 0; }
      .create-job-progress ul.checkout-bar li {
        display: inline-block;
        margin: 35px 0 0;
        padding: 0;
        text-align: center;
        width: 25%; }
        .create-job-progress ul.checkout-bar li:before {
          height: 3rem;
          width: 3rem;
          left: 44%;
          line-height: 3rem;
          position: absolute;
          top: -47px;
          z-index: 1; }
        .create-job-progress ul.checkout-bar li.visited {
          background: none; }
          .create-job-progress ul.checkout-bar li.visited:after {
            background-color: #0898c4;
            content: '';
            height: 0.6rem;
            left: 50%;
            position: absolute;
            top: -35px;
            width: 100%; } }

.create-job-listing {
  font-size: 1.6rem; }
  .create-job-listing div.algorithm {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 169, 207, 0.2);
    padding: 1rem; }
    @media screen and (min-width: 768px) {
      .create-job-listing div.algorithm {
        padding: 2rem; } }
  .create-job-listing .progress {
    height: 1rem; }
  .create-job-listing h1.create-job-listing-title {
    border-bottom: 1px solid #EEE;
    padding-bottom: 0.5rem;
    margin-top: 0; }
  .create-job-listing .form-step h2, .create-job-listing .publish-job-listing h2 {
    font-weight: bold;
    margin: 0 0 2rem 0; }
  .create-job-listing .description {
    margin-bottom: 4rem; }
  .create-job-listing .help-text {
    color: #777;
    font-size: 90%; }
  .create-job-listing .form-container {
    min-height: 47rem; }
  .create-job-listing .delete-button i {
    vertical-align: -webkit-baseline-middle; }
  .create-job-listing .publish-description {
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .create-job-listing .publish-description {
        margin-bottom: 4rem; } }
  .create-job-listing .publish-explanation {
    margin-bottom: 2rem; }
    @media screen and (min-width: 992px) {
      .create-job-listing .publish-explanation {
        margin-bottom: 0; } }
  .create-job-listing .publish-action {
    float: none;
    margin-bottom: 1rem; }
    @media screen and (min-width: 480px) {
      .create-job-listing .publish-action {
        margin-bottom: 0; } }
  .create-job-listing .radio, .create-job-listing .checkbox {
    margin-top: 0; }

.extra-rate-info label.control-label {
  font-size: smaller;
  color: gray; }

.extra-rate-info p {
  font-size: small; }
